@use 'mixins' as *;

.header {
  align-items: center;
  display: flex;
  block-size: var(--header-height, 64px);
  inset-inline: 0;
  position: fixed;
  inset-block-start: 0;
  inline-size: 100%;
  z-index: 10;
}

.menu-item {
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  margin: 0;
  block-size: 100%;
  font-size: var(--font-size-paragraph16);
  font-weight: var(--font-weight-medium);

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.has-submenu {
  &::after {
    content: '';
    position: absolute;
    inset-inline: 100%;
    border-block-end: thin solid var(--color-grey3);
    block-size: 100%;
    inset-block-end: var(--spacing-xs);
    margin-inline: auto;
    transition: inset 0.2s ease-out;
  }
  &.open,
  &:hover {
    &::after {
      inset-inline: max(var(--spacing-s, 1rem), 33%);
    }
  }
  &.open {
    &::after {
      border-color: var(--color-system-black);
    }
  }
}

.desktop-menu {
  align-items: center;
  display: flex;
  block-size: 100%;
  gap: var(--spacing-s);
  padding-block: var(--spacing-xs);
  @include breakpoint-max(large) {
    display: none;
  }
}

.inner {
  display: flex;
  flex-grow: 1;
  block-size: 100%;
  position: relative;
}
