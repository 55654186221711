@use 'mixins' as *;

.hero {
  &.above {
    margin-block-start: 72px;
  }
}

.hero .description {
  color: var(--color-white, inherit);
  max-inline-size: 800px;
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight-medium, inherit);
}

.hero.above .description {
  max-inline-size: 100%;
  color: var(--color-black, inherit);
}

.hero .title {
  margin: 0;
  padding: 0;
  text-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

.hero.above .title {
  text-shadow: none;
  color: var(--color-wibe-dark-grey, inherit);
  font-size: var(--font-size-header1, inherit);
  font-style: normal;
  font-weight: var(--font-weight-light, inherit);
  line-height: var(--line-height-header1, inherit);
  text-align: start;

  @include breakpoint-max(small) {
    font-size: var(--font-size-header1, inherit);
    line-height: var(--line-height-header1, inherit);
  }
}

.image-container {
  position: absolute;
  inset: 0;
}

.background {
  position: relative;
  background-color: var(--color-wibe-dark-grey);

  .above & {
    margin-block-start: var(--spacing-m);
  }

  @include breakpoint-max(small) {
    aspect-ratio: var(--aspect-ratio, 3 / 4);
    .above & {
      aspect-ratio: var(--aspect-ratio, 1 / 1);
    }
  }
  @include breakpoint-min(small) {
    aspect-ratio: var(--aspect-ratio-tablet, 3 / 4);
  }

  @include breakpoint-min(medium) {
    aspect-ratio: var(--aspect-ratio-desktop, 16 / 9);
    .wide & {
      aspect-ratio: var(--aspect-ratio-desktop, 21 / 9);
    }
  }

  img {
    object-fit: cover;
    inline-size: 100%;
    block-size: 100%;
  }
}

.content {
  z-index: 1;
  position: relative;
  justify-content: flex-end;
  display: flex;
  gap: var(--spacing-m);
  flex-direction: column;
  inline-size: 100%;
  block-size: 100%;

  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 36.82%,
    rgb(0 0 0 / 40%) 100%
  );
  padding-block: var(--spacing-l);
  padding-inline: var(--spacing-s);

  @include breakpoint-min(small) {
    padding-inline: var(--spacing-side) 4%;
  }
}

.above .content {
  background-image: none;
  padding-block: var(--spacing-l);
  padding-inline: 0;
  margin-inline: var(--spacing-s);
  @include breakpoint-min(small) {
    margin-inline: auto;
    inline-size: 66vw;
    max-inline-size: 60rem;
    padding-inline: 0;
  }
}

.preamble {
  margin-block-end: var(--spacing-m);
  text-shadow: 0 0 0.5rem rgb(0 0 0 / 50%);
  max-inline-size: 62rem;
}

.button-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--spacing-s-negative);
  margin-top: var(--spacing-s-negative);

  & > * {
    margin-inline-start: var(--spacing-s);
    margin-block-start: var(--spacing-s);
  }

  &:empty {
    display: none;
  }
}

.label {
  margin-block-end: var(--spacing-xs);
  text-shadow: 0 0 4px rgb(0 0 0 / 50%);
}
