@use 'mixins' as *;

.divider {
  @include content-width;
  border: 0;
  border-bottom: thin solid var(--color-divider, var(--color-grey2));
  margin-block: var(--spacing-m);

  &.extra-margin {
    margin-block: var(--spacing-xl);
  }

  @include breakpoint-min(small) {
    margin-block: var(--spacing-m);
    &.extra-margin {
      margin-block: var(--spacing-l);
    }
  }
}
