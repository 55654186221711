.footer {
  --footer-bg-color: var(--color-wibe-dark-grey, rgb(48, 62, 71));
  --footer-font-family-heading: var(--font-family);
  --footer-font-family-regular: var(--font-family);
  --footer-font-weight-bold: var(--font-weight-extra-bold, 800);
  --footer-font-weight-regular: var(--font-weight-regular, 400);
  --footer-heading-font-size: var(--font-size-header5, 1.125rem);
  --footer-heading-line-height: var(--line-height-header4, 26px);
  --footer-logo-color: var(--color-white, #fff);
  --footer-logo-size: var(--spacing-l, 48px);
  --footer-spacing-l: var(--spacing-l, 48px);
  --footer-spacing-small: var(--spacing-s, 16px);
  --footer-text-color-hover: var(--color-grey2-hover, rgb(208, 210, 212));
  --footer-text-color: var(--color-white, #fff);
}

.logo {
  position: relative;
  inline-size: 140px;
  block-size: 60px;
  svg {
    inline-size: 100%;
    block-size: 100%;
  }
}
