.main {
  display: block;
  inline-size: 100%;
  max-inline-size: 100dvi;
  max-inline-size: 100vi;

  &:empty {
    display: none;
  }
}
