@use 'mixins' as *;

.container {
  display: block;
  margin: var(--spacing-l, inherit) auto;
  padding-left: var(--spacing-s, inherit);
  padding-right: var(--spacing-s, inherit);

  @include breakpoint-min(small) {
    margin: var(--spacing-xl, inherit) auto;
    padding-inline: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
}

.video {
  align-self: center;
  max-height: calc(100vh - 56px);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}

.play {
  background: none;
  background: rgb(234 234 234 / 50%);
  background-color: var(--color-wibe-yellow, inherit);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 56px;
  left: 50%;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.visible {
    opacity: 1;
    pointer-events: initial;
    transition: opacity 0.3s ease;
  }

  &::before {
    @include breakpoint-min(small) {
      background: var(--color-wibe-yellow-hover, inherit);
      content: '';
      block-size: 100%;
      inset-inline-start: 0;
      pointer-events: none;
      position: absolute;
      inset-block-start: 0;
      transform: translateX(-100%);
      inline-size: 100%;
      z-index: -1;
    }
  }

  &:hover::before {
    transform: translateX(0);
  }

  @include breakpoint-min(medium) {
    block-size: var(--spacing-xl, inherit);
    inline-size: var(--spacing-xl, inherit);
  }
}

.icon {
  height: 16.47px;
  width: 14.06px;

  @include breakpoint-min(medium) {
    block-size: 28.23px;
    inline-size: 24.13px;
  }

  path {
    fill: var(--color-wibe-dark-grey, inherit);
    opacity: 1;
  }
}

.description {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-paragraph10, inherit);
  margin-top: var(--spacing-s, inherit);
}

.label {
  color: var(--color-grey3, inherit);
  margin-right: var(--spacing-m, inherit);
}

.title {
  line-height: var(--line-height-header4, inherit);
  margin-top: 1px;
}
