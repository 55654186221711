@use 'mixins' as *;

.address-block {
  margin-block-end: var(--spacing-m);

  svg {
    margin-inline-end: var(--spacing-xs);
  }

  .container & {
    margin: 0;
    grid-column: 1 / span 12;
    @include breakpoint-min(small) {
      grid-column: 3 / span 8;
    }
    @include breakpoint-min(medium) {
      grid-column: 4 / span 6;
    }
  }
}

.container {
  margin-block: var(--spacing-xl);
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;

  &.directions {
    margin-block-start: var(--spacing-s);
  }

  &:hover {
    color: var(--colors-system-active);
  }
}

.section {
  &:last-of-type {
    margin-block-end: 0;
  }

  & + & {
    margin-block-start: var(--spacing-m);
  }
}

.section-inner {
  &:empty {
    display: none;
  }
}

.title {
  margin-block-end: var(--spacing-xs);
}

.disclaimer {
  margin-block-start: var(--spacing-m);
}
