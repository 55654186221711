@use 'mixins' as *;

.section {
  inline-size: calc(100dvw - var(--spacing-s) - var(--spacing-s));
  inline-size: calc(100dvi - var(--spacing-s) - var(--spacing-s));
  max-inline-size: var(--content-max-width);

  margin-inline: auto;
  margin-block: var(--margin-top, 0) 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-s);

  &.narrow {
    max-inline-size: 86rem;
  }

  &.spacing-bottom,
  &:last-child {
    margin-block-end: var(--spacing-xl);
  }

  &.spacing-top {
    margin-block-start: var(--spacing-xl);
  }

  @include breakpoint-min(small) {
    inline-size: calc(100dvw - var(--spacing-side) - var(--spacing-side));
    inline-size: calc(100dvi - var(--spacing-side) - var(--spacing-side));
  }
}

.title {
  margin: var(--spacing-s) 0 var(--spacing-xs);

  @include breakpoint-min(small) {
    margin: var(--spacing-m) 0 var(--spacing-s);
  }
}

.aspect {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
}

.image {
  height: 100%;
  inset: 0;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

.document {
  background-color: var(--color-grey1);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: thin solid var(--color-grey2);
  color: var(--color-grey3);

  svg {
    inline-size: 50%;
    block-size: 50%;
  }
}
